import { render, staticRenderFns } from "./VideoEditingRestrict.vue?vue&type=template&id=472553ce&scoped=true&"
import script from "./VideoEditingRestrict.vue?vue&type=script&lang=js&"
export * from "./VideoEditingRestrict.vue?vue&type=script&lang=js&"
import style0 from "./VideoEditingRestrict.vue?vue&type=style&index=0&id=472553ce&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472553ce",
  null
  
)

export default component.exports