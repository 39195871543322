import { render, staticRenderFns } from "./EditorCustomize.vue?vue&type=template&id=2844d1aa&"
import script from "./EditorCustomize.vue?vue&type=script&lang=js&"
export * from "./EditorCustomize.vue?vue&type=script&lang=js&"
import style0 from "../../assets/less/custom/fonts.less?vue&type=style&index=0&prod&lang=less&"
import style1 from "./EditorCustomize.vue?vue&type=style&index=1&id=2844d1aa&prod&lang=less&"
import style2 from "../../assets/less/custom/editor.less?vue&type=style&index=2&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports